body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply antialiased bg-primary;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  @apply light;
}

.dark {
  --color-bg-primary: #312c2c;
  --color-bg-secondary: #2b332a;
  --color-bg-third: #2ba444;
  --color-text-primary: #ffffff;
  --color-text-secondary: #71ffd7;
  --color-text-accent: #2ba444;
}

.light {
  --color-bg-primary: #ffffff;
  --color-bg-secondary: #edf2f7;
  --color-bg-third: #f3f4f6;
  --color-text-primary: #4b5563;
  --color-text-secondary: #4a5568;
  --color-text-accent: #2b6cb0;
}

@layer utilities {
  .border-spacing {
    border-spacing: 1rem;
  }
}

@layer components {
  /* SWIPER CUSTOM */
  /* -------------------------------------------- */
  .cockpitSwiper .swiper-pagination-bullet {
    background-color: #808080;
    padding: 0.1rem;
  }
  .cockpitSwiper .swiper-pagination-bullet-active {
    background-color: #71ffd7e6;
  }
  .cockpitSwiper .swiper-button-prev,
  .cockpitSwiper .swiper-button-next {
    color: #808080;
  }

  .cockpitSwiper .swiper-button-prev:hover,
  .cockpitSwiper .swiper-button-next:hover {
    color: #71ffd7e6;
  }

  /* SCROLL */
  /* -------------------------------------------- */
  .container-scroll::-webkit-scrollbar {
    width: 5px;
  }
  .container-scroll::-webkit-scrollbar-thumb {
    background-color: #71ffd7e6;
    border-radius: 2rem;
  }

  /* DETAILS & SUMMARY */
  /* -------------------------------------------- */
  details {
    user-select: none;
  }
  details[open] summary svg {
    transform: rotate(90deg);
  }
  summary {
    cursor: pointer;
  }
  details svg {
    transition: all 0.3s;
  }
  summary::-webkit-details-marker {
    display: none;
  }
  summary:focus {
    outline: none;
  }

  /* TABLE */
  /* -------------------------------------------- */
  .table-PrioriryList th:first-child {
    border-top-left-radius: 0.75rem !important;
  }
  .table-PrioriryList th:last-child {
    border-top-right-radius: 0.75rem;
  }
  .table-PrioriryList tr:last-child td:first-child {
    border-bottom-left-radius: 0.75rem;
  }
  .table-PrioriryList tr:last-child td:last-child {
    border-bottom-right-radius: 0.75rem;
  }

  /* TOGGLE BTN */
  /* -------------------------------------------- */
  .toggle-checkbox:checked {
    @apply: right-0 border-green-400;
    right: 0;
    border-color: #71ffd7;
  }
  .toggle-checkbox:checked + .toggle-label {
    @apply: bg-green-400;
    background-color: #71ffd7;
  }

  /* CHECKBOX */
  /* -------------------------------------------- */
  .custom_ckBox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    width: 1rem;
    height: 1rem;
    border-radius: 0.25rem;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    background-color: #71ffd7e6;
    padding: 0.7rem;
    cursor: pointer;
  }
  .custom_ckBox::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    -webkit-clip-path: polygon(
      14% 44%,
      0 65%,
      50% 100%,
      100% 16%,
      80% 0%,
      43% 62%
    );
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* background-color: CanvasText; */
    background-color: black;
  }
  .custom_ckBox:checked::before {
    transform: scale(1);
  }
  .custom_ckBox:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
  }
  .custom_ckBox:disabled {
    --form-control-color: var(--form-control-disabled);
    color: var(--form-control-disabled);
    cursor: not-allowed;
  }

  /* RADIO BTN */
  /* -------------------------------------------- */
  .labelCustom {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
    padding: 0.5rem 0;
  }
  .labelCustom .inputCustom {
    position: absolute;
    left: -9999px;
  }
  .labelCustom .inputCustom:checked + .spanCustom:before {
    box-shadow: inset 0 0 0 0.4375em #71ffd7e6;
  }
  .labelCustom .spanCustom {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
  }
  .labelCustom .spanCustom:before {
    display: flex;
    flex-shrink: 0;
    content: "";
    background-color: #312c2c;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em #312c2c;
  }

  /* SELECT */
  /* -------------------------------------------- */
  .labelRadio {
    position: relative;
    min-width: 60%;
    margin: 0.75rem;
  }
  .labelRadio .svgRadio {
    position: absolute;
    right: 12px;
    top: calc(40% - 3px);
    width: 20px;
    height: 12px;
    stroke-width: 2px;
    stroke: black;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    pointer-events: none;
  }
  .labelRadio .selectRadio {
    -webkit-appearance: none;
    padding: 7px 40px 7px 12px;
    width: 100%;
    border-radius: 1rem;
    box-shadow: 0 1px 3px -2px black;
    cursor: pointer;
    font-family: inherit;
    font-weight: 600;
    font-size: 12px;
    transition: all 550ms ease;
    color: black;
    background-color: #71ffd7e6;
  }
  .labelRadio .selectRadio .optionRadio[value="default"][disabled] {
    display: none;
  }
  .labelRadio select.selectRadio {
    outline: none;
    box-shadow: 0 0 0 2px #71ffd7e6;
  }
  .labelRadio .selectRadio + .svgRadio {
    stroke: black;
  }
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
